<template>
    <component
        :is="calculatedElement"
        :to="to"
        :href="href"
        class="icon-button"
        :class="[
            'icon-button--' + calculatedSize,
            filled ? 'icon-button--filled-' + filled : '',
            hoverColor ? 'icon-button--hover-' + hoverColor : '',
            text ? 'icon-button--with-text' : '',
            'icon-button--text-' + calculatedTextColor,
        ]"
        @mouseover="cursorStore.setCursorType('link-hover')"
        @mouseleave="cursorStore.setCursorType('default')"
    >
        <span v-if="text" class="icon-button-text" v-html="text" />
        <FontIcon
            v-else
            :icon="icon"
            :color="calculatedIconColor"
            :size="calculatedIconSize"
            :style="iconStyle"
        />
    </component>
</template>
<script>
import { validIcons } from '~/assets/js/config/validators'
import { CustomLink } from '#components'

export default {
    components: {
        CustomLink,
    },

    props: {
        icon: {
            default: 'arrow',
            type: String,
            validator(value) {
                return validIcons.includes(value)
            },
        },
        color: {
            default: 'black',
            type: String,
            validator(value) {
                return ['white', 'black'].includes(value)
            },
        },
        size: {
            default: 'medium',
            type: String,
            validator(value) {
                return [
                    'xx-small',
                    'x-small',
                    'small',
                    'medium',
                    'large',
                    'stretch',
                ].includes(value)
            },
        },
        iconSize: {
            default: 'medium',
            type: String,
            validator(value) {
                return [
                    'xx-small',
                    'x-small',
                    'small',
                    'medium',
                    'large',
                ].includes(value)
            },
        },
        filled: {
            default: 'black',
            type: String,
            validator(value) {
                return ['none', 'white', 'black'].includes(value)
            },
        },
        hoverColor: {
            default: null,
            type: String,
        },
        rotate: {
            default: 0,
            type: Number,
        },
        to: {
            type: String,
            default: null,
            required: false,
        },
        href: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: '',
        },
        element: {
            type: String,
            default: 'button',
            validator(value) {
                return ['div', 'button'].includes(value)
            },
        },
    },

    setup() {
        const cursorStore = useCursorStore()

        return { cursorStore }
    },

    computed: {
        iconStyle() {
            if (this.rotate) {
                return {
                    transform: 'rotate3d(0, 0, 1, ' + this.rotate + 'deg)',
                }
            }
            return null
        },
        calculatedSize() {
            return this.size
        },
        calculatedIconSize() {
            if (this.text) {
                return 'x-small'
            } else if (this.iconSize) {
                return this.iconSize
            }

            return this.size
        },
        calculatedTextColor() {
            if (this.filled === 'black') {
                return 'white'
            } else if (this.filled === 'white') {
                return 'black'
            }

            return 'black'
        },
        calculatedIconColor() {
            if (this.filled === 'black') {
                return 'white'
            } else if (this.filled === 'white') {
                return 'black'
            }

            return 'white'
        },
        calculatedElement() {
            if (this.to || this.href) return CustomLink

            if (this.element) return this.element
        },
    },
}
</script>
<style lang="scss">
.icon-button {
    position: relative;
    box-shadow: 0 0 0 1px var(--white) inset;
    border-radius: 100%;
    border: 0;
    outline: 0;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: var(--white);
        transition: width 0.3s 0s ease;
    }

    &:focus,
    &:hover {
        &::before {
            width: 100%;
        }

        .icon {
            fill: var(--black);
        }
    }

    .font-icon {
        position: relative;
        transition: transform 0.3s 0s ease;
    }
}

.icon-button--with-text {
    display: flex;
    align-items: center;
    aspect-ratio: 1/1;
    min-width: 6.25rem;

    &:focus,
    &:hover {
        &::before {
            width: 0%;
        }
    }
}

// ICON TEXT
.icon-button-text {
    @include label-sm;

    display: block;
    width: 100%;
    text-align: center;
    margin-top: -4px;
}

.icon-button--text-white {
    .icon-button-text {
        color: var(--white);
    }
}

.icon-button--text-black {
    .icon-button-text {
        color: var(--black);
    }
}

// SIZES
.icon-button--xx-small {
    padding: 8px;
}

.icon-button--x-small {
    padding: 12px;
}

.icon-button--small {
    padding: 16px;
}

.icon-button--medium {
    padding: 20px;
}

.icon-button--large {
    padding: 24px;
}

// FILLED
.icon-button--filled-black {
    background-color: var(--black);
    box-shadow:
        0 0 0 1px var(--black) inset,
        0px 1px 2px 0px rgba(var(--white-rgb), 0.2);
}

.icon-button--filled-white {
    background-color: var(--white);
    box-shadow:
        0 0 0 1px var(--white) inset,
        0px 1px 2px 0px rgba(var(--black-rgb), 0.2);
}

.icon-button--filled-none {
    box-shadow: none;

    &.icon-button--hover-black {
        .icon {
            fill: var(--black);
        }
    }

    &:focus,
    &:hover {
        &::before {
            width: 0;
        }
    }
}

// HOVER
.icon-button--hover-white:focus,
.icon-button--hover-white:hover {
    &.icon-button {
        box-shadow: 0 0 0 1px var(--white) inset;
    }

    .icon {
        fill: var(--white);
    }
}

.icon-button--hover-black {
    box-shadow: 0 0 0 1px var(--black) inset;

    &::before {
        background-color: var(--black);
    }

    &:focus,
    &:hover {
        &.icon-button {
            border-color: var(--black);
        }

        .font-icon .icon {
            fill: var(--white);
        }
    }
}
</style>
